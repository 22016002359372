/* eslint-disable camelcase */
import { Expose, plainToClass, Transform } from 'class-transformer';
import RatesHotelModel from './rates-hotel.model';

type HotelId = number;

export default class RatesCheckinDayModel {
    @Expose({ name: 'update_date' })
    updateDate?: string | null;

    @Expose()
    @Transform((_, { day_statistics }) => (Number.isFinite(day_statistics?.demand)
        ? Math.max(0, Math.round(day_statistics.demand * 100))
        : null))
    demand!: number | null;

    @Expose()
    @Transform((_, { day_statistics }) => (Number.isFinite(day_statistics?.occupancy)
        ? Math.max(0, Math.round(day_statistics.occupancy))
        : null))
    occupancy!: number | null;

    @Expose()
    @Transform((_, plain) => {
        const hotels: any = {};
        Object.keys(plain).forEach(hotelId => {
            if (Number.isNaN(+hotelId)) return;

            hotels[+hotelId] = plainToClass(RatesHotelModel, plain[hotelId], { excludeExtraneousValues: true });
        });

        return hotels;
    })
    hotels: Record<HotelId, RatesHotelModel> = {};
}
