import { Expose, plainToClass, Transform } from 'class-transformer';
import { Validate, IsNumber, IsOptional } from 'class-validator';

import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import RatesCheckinDatesValidator from '@/modules/rates/models/validators/checkin-dates.validator';
import { SCAN_DEFAULT_ESTIMATION, SCAN_DEFAULT_ESTIMATION_ONE_DAY } from '../constants';
import { RatesDocumentScanValues } from './rates-document-scan.model';
import RatesCheckinDayModel from './rates-checkin-day.model';

type Day = number;

export default class RatesDocumentModel {
    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => plain._id)
    @IsNumber()
    id!: number;

    @Expose()
    @Transform((_, plain) => {
        const checkinDates: any = {};

        if (!plain.checkin_dates) {
            return plain.checkin_dates;
        }

        Object.keys(plain.checkin_dates).forEach(day => {
            checkinDates[day] = plainToClass(
                RatesCheckinDayModel,
                plain.checkin_dates[day],
                { excludeExtraneousValues: true },
            );
        });

        return checkinDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    checkinDates?: Record<Day, RatesCheckinDayModel>;

    @Expose()
    @Transform((_, plain) => plain.update_date && new Date(plain.update_date))
    updateDate!: Date | null;

    @Expose()
    @Transform((_, plain) => plain.currency)
    currency?: string;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    @Transform((_, plain) => plain.hotelsName)
    hotelNames!: { [hotelId: number]: string };

    @Expose()
    @Transform((_, plain) => ({
        id: (plain.scan_id && plain.scan_id.length) ? plain.scan_id[plain.scan_id.length - 1] : null,
        status: plain.status || SCAN_STATUS.FINISHED,
        estimation: plain.ui_notify?.estimated_time_one_day_sec
            ? plain.ui_notify.estimated_time_one_day_sec * plain.ui_notify.last_trigger_days
            : SCAN_DEFAULT_ESTIMATION,
        estimationOneDay: plain.ui_notify?.estimated_time_one_day_sec || SCAN_DEFAULT_ESTIMATION_ONE_DAY,
        startTime: plain.status === SCAN_STATUS.IN_PROGRESS
            ? plain.ui_notify?.last_trigger_time || (new Date()).toISOString()
            : null,
        endTime: plain.update_date || null,
        ratio: 0,
    }))
    scan!: Omit<RatesDocumentScanValues, 'documentId'>;

    /**
     * Pass 'requested_pos' query parameter to get this property
     */
    @Expose()
    @Transform((_, plain) => plain.exchange_rate || 1)
    exchangeRate!: number;

    @Expose()
    @Transform((_, plain) => plain.pos)
    pos!: string;

    @Expose()
    @Transform(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    numberOfGuestsOptions!: number[];
}
