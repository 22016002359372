import { Expose, plainToClass, Transform } from 'class-transformer';
import RatesDocumentItemModel from './rates-document-item.model';
import RatesHotelModel from './rates-hotel.model';

export default class RatesDocumentIntradayModel {
    @Expose()
    @Transform((_, plain) => (
        Object.entries(plain).reduce((acc, [hotelId, value]) => {
            const rooms = Object
                .entries((value as { rooms: {} }).rooms || {})
                .reduce((roomsAcc, [roomTypeId, rooms]) => {
                    const roomList = plainToClass(RatesDocumentItemModel, rooms as {}[], { excludeExtraneousValues: true });
                    const res = { ...roomsAcc, [roomTypeId]: roomList };

                    return res;
                }, {} as { [roomTypeId: string]: RatesDocumentItemModel[] });

            acc[+hotelId] = {
                rooms,
                link: null,
                screenshot: null,
            };

            return acc;
        }, {} as Record<number, RatesHotelModel>)
    ))
    hotels!: Record<number, RatesHotelModel>;
}
