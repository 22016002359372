import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '../common/models/loading.model';
import Day from '../common/types/day.type';
import RVSDocumentModel, { RVSHotelData } from './models/rvs-document.model';
import { RVSSettingsModel } from './models/rvs-settings.model';

interface RVSCalendarState {
    loading: LoadingModel;
    currentDay: Day;
    data: RVSDocumentModel | null;
}

interface RVSOvertimeState {
    loading: LoadingModel;
    data: RVSDocumentModel[];
}

interface RVSScoreState {
    data: RVSDocumentModel | null;
    loading: LoadingModel;
}

interface RVSDetailsState {
    data: RVSHotelData | null;
    loading: LoadingModel;
}

@Module
export default class RVSStore extends VuexModule {
    settings: RVSSettingsModel = new RVSSettingsModel();
    score: RVSScoreState = {
        data: null,
        loading: new LoadingModel(),
    };

    calendar: RVSCalendarState = {
        loading: new LoadingModel(),
        currentDay: new Date().getDate() as Day,
        data: null,
    };

    overtime: RVSOvertimeState = {
        loading: new LoadingModel(),
        data: [],
    };

    details: RVSDetailsState = {
        loading: new LoadingModel(),
        data: null,
    };
}
