import { validate, ValidationError } from 'class-validator';
import { inject, injectable } from '@/inversify';
import * as _ from 'lodash';

import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsApiService, { EventsApiServiceS } from '@/modules/events/events-api.service';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import EventsModel from '@/modules/events/models/events.model';
import EventsStore from '@/modules/events/store/events.store';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars/cars-filters.service';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';
import EventCollection from './models/event-collection.model';
import EventGroup from './interfaces/event-group.enum';
import { EventSet } from './models/event-set.model';
import EventsExcelFormModel from './models/events-excel-form.model';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';

interface EventsManagerPublicInterface {
    /**
     * List of events that are ignored by the user
     */
    ignoredEvents: EventSet;

    /**
     * Returns NOT filtered events for provided date.
     * @param date
     */
    getEventCollection(date: Date): EventCollection | null

    /**
     * Returns filtered ALL events for provided date.
     * Holidays filtered by Holiday filter.
     * My, suggested and chain events filtered by Events filter but not by Status.
     * @param date
     */
    getEventsByDate(date: Date): EventsModel[] | null

    /**
     * Returns an EventSet for the specified hotel ID in case it's defined
     * otherwise returns `null`
     *
     * @param hotelId
     */
    getEventSetByHotel(hotelId: number): EventSet | null;

    getExcel(form: EventsExcelFormModel): Promise<boolean>;

    /**
     * Adds the event into ignore list
     */
    ignoreEvent(eventId: string): Promise<ValidationError[]>;

    /**
     * Removes the event from ignore list
     */
    restoreIgnoredEvent(id: string): Promise<ValidationError[]>;
}

export const EventsManagerServiceS = Symbol.for('EventsManagerServiceS');
@injectable()
export default class EventsManagerService implements EventsManagerPublicInterface {
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(EventsApiServiceS) private eventsApiService!: EventsApiService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;

    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], ((newValue, oldValue) => {
            const [newMonth, newYear] = newValue;
            const [oldMonth, oldYear] = oldValue;

            if (newMonth === oldMonth
                && newYear === oldYear) {
                return;
            }

            this.eventSet.loading.reset();
        }));

        if (this.userService.isClusterUser || this.userService.isChainUser) {
            this.storeFacade.watch(() => [
                this.userService.viewAs,
                this.userService.currentHotelId,
            ], ((newValue, oldValue) => {
                const [oldViewAs, oldCurrentHotelId] = oldValue;
                const [newViewAs, newCurrentHotelId] = newValue;

                if (oldCurrentHotelId === newCurrentHotelId
                    && oldViewAs === newViewAs) {
                    return;
                }

                this.eventSet.loading.reset();
                this.storeState.loadingIgnores.reset();
            }));
        }
    }

    get eventSet() {
        this.storeState.events[this.currentEntityId] = this.storeState.events[this.currentEntityId] || new EventSet();
        this.helperService.dynamicLoading(this.storeState.events[this.currentEntityId].loading, this.loadData.bind(this));

        return this.storeState.events[this.currentEntityId];
    }

    get settings() {
        return this.storeState.settings;
    }

    get isLoading() {
        return this.eventSet.loading.isLoading() || (this.ignoredEvents && this.storeState.loadingIgnores.isLoading());
    }

    get ignoredEvents() {
        this.helperService.dynamicLoading(this.storeState.loadingIgnores, async () => {
            const events = await this.eventsApiService.getIgnoredEvents();
            this.storeState.ignoredEvents.clear();
            this.storeState.ignoredEvents.append(events);
            return true;
        });
        return this.storeState.ignoredEvents;
    }

    private get currentEntityId() {
        if (this.userService.isCarUser) {
            return 'cars';
        }

        const type = this.userService.viewAs;

        if (this.userService.isViewAsHotel) {
            return +this.userService.currentHotelId!;
        }

        return `${type} - ${this.userService.chainId}`;
    }

    /**
     * For CI and Cars (in events manager page)
     */
    private async loadData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const { previousMonthAndYear, nextMonthAndYear } = this.documentFiltersService;
        const { currentCompset } = this.compsetsService;
        const { isViewAsHotel, isChainOrClusterUser, isHotelUser } = this.userService;
        const { isCarUser } = this.userService;

        const isHotelLevel = isViewAsHotel || (!isChainOrClusterUser && isHotelUser);
        const isCompsetDefined = !!currentCompset;

        if (!isCarUser && isHotelLevel && !isCompsetDefined) {
            await this.compsetsService.storeState.loading.whenLoadingFinished();
        }

        await Promise.all([
            this.loadMonthEvents((settings.month) as Month, settings.year),
            this.loadMonthEvents((previousMonthAndYear.month) as Month, previousMonthAndYear.year),
            this.loadMonthEvents((nextMonthAndYear.month) as Month, nextMonthAndYear.year),
        ]);

        await this.loadDefaultCountries();

        return true;
    }

    private async loadDefaultCountries() {
        const { isChainOrClusterUser, isCarUser, viewAs } = this.userService;
        const isClusterUser = isChainOrClusterUser && viewAs !== 'hotel';

        let defaultCountryCodes = [] as string[];

        if (isCarUser) {
            defaultCountryCodes = this.carsFiltersService.settings.pos || [];
        } else {
            defaultCountryCodes = isClusterUser
                ? await this.clusterService.getPosList()
                : this.compsetsService.poses;
        }

        this.eventsFilterService.setSettings(this.eventSet, { countries: defaultCountryCodes || undefined });
    }

    /**
     * Collects loaded events into `EventCollection` and places in the store for each day
     */
    private registerEventsInCollections(events: EventsModel[], year?: number, month?: number, reactive = false) {
        const { eventSet } = this;

        if (!eventSet) return;

        eventSet.append(events);

        if (year && month) {
            eventSet.setLoaded(year, month);
        }

        if (reactive) {
            this.storeState.events = {
                ...this.storeState.events,
                [this.currentEntityId]: eventSet.duplicate(),
            };
        }
    }

    private async loadMonthEvents(month: Month, year: Year, pos?: string[]): Promise<void> {
        const { isViewAsCluster, currentHotelId } = this.userService;

        if (!currentHotelId && !isViewAsCluster) {
            return;
        }

        const eventPromises = [
            this.eventsApiService.getHolidaysEvents((month + 1) as Month, year, pos),
        ];

        let holidayEvents = null as EventsModel[] | null;
        let myEvents = null as EventsModel[] | null;
        let chainEvents = null as EventsModel[] | null;
        let marketEvents = null as EventsModel[] | null;

        if (isViewAsCluster) {
            const { chainId, viewAs } = this.userService;

            eventPromises.push(this.eventsApiService.getChainEvents(month as Month, year, chainId!, viewAs!));
            [holidayEvents, chainEvents] = await Promise.all(eventPromises);
        } else {
            // TODO Use `loadHotelEvent` method here and then put the holiday events into the event set

            const { currentCompset } = this.compsetsService;
            const isCurrentsHotelCompset = currentCompset && currentCompset.ownerHotelId === currentHotelId;

            const marketId = isCurrentsHotelCompset
                ? currentCompset!.marketId
                : this.compsetsService.getCompsetsByHotel(currentHotelId!)[0]?.marketId;

            eventPromises.push(
                this.eventsApiService.getMyEvents(month as Month, year, currentHotelId!, marketId),
            );
            [holidayEvents, myEvents] = await Promise.all(eventPromises);

            marketEvents = (myEvents || []).filter(event => event.entityType === 'market');
            myEvents = (myEvents || []).filter(event => event.entityType !== 'market');
        }

        this.registerEventsInCollections(holidayEvents || [], month, year);
        this.registerEventsInCollections(myEvents || [], month, year);
        this.registerEventsInCollections(chainEvents || [], month, year, true);
        this.registerEventsInCollections(marketEvents || [], month, year);
    }

    async loadHotelEvent(m: Month, y: Year, hotelId: number, marketId?: number) {
        let eventSet = this.storeState.events[hotelId];
        const hash = `${hotelId}-${m}-${y}-${marketId}`;
        const meta = this.loadHotelEvent as any;

        meta.activePromises = meta.activePromises || {};

        if (eventSet && eventSet.hasLoaded(y, m)) {
            return eventSet;
        }

        const promise = (meta.activePromises[hash] || this.eventsApiService.getMyEvents(m, y, hotelId, marketId));

        meta.activePromises[hash] = promise;

        const events = (await promise) || [];

        delete meta.activePromises[hash];

        if (!eventSet) {
            eventSet = new EventSet().append(events);
        } else {
            eventSet.append(events);
        }

        eventSet.setLoaded(y, m);

        this.storeState.events = {
            ...this.storeState.events,
            [hotelId]: eventSet,
        };

        return eventSet;
    }

    getEventById(eventId: string) {
        return this.eventSet.get(eventId);
    }

    async addEvent(newEvent: EventsModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(newEvent);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const { user, currentHotelId, isCarUser } = this.userService;

            const { currentCompset } = this.compsetsService;
            let marketId = currentCompset ? +currentCompset.marketId : null;
            const isClusterView = this.userService.isViewAsCluster || this.userService.isViewAsChain;

            if (isClusterView) {
                marketId = null;
            }

            if (!user || (!currentHotelId && isCarUser)) {
                return validationErrors;
            }

            const creationParams = { ...newEvent, marketId };

            const event = await this.eventsApiService.createEvent(creationParams, user.id);

            if (event) {
                this.registerEventsInCollections([event], undefined, undefined, true);
            }
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    /**
     * Updates data of the event
     *
     * @param event is actually copy of the original event with updated fields so original event will be removed
     */
    async updateEvent(event: EventsModel): Promise<ValidationError[]> {
        const modelValue = Object.assign(new EventsModel(), event);

        let validationErrors: ValidationError[] = await validate(modelValue);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const newEvent = await this.eventsApiService.updateEvent(event);
            if (!newEvent) {
                return validationErrors;
            }

            await this.removeEvent(event.id!, true);
            this.registerEventsInCollections([newEvent], undefined, undefined, true);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    /**
     * Removes the specified event
     * @param eventId
     * @param localyOnly means that it will be removed on client-side only
     */
    async removeEvent(eventId: string, localyOnly = false): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            if (!localyOnly) {
                await this.eventsApiService.removeLocalEvent(eventId);
            }

            this.eventSet.remove(eventId);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async ignoreEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            await this.eventsApiService.ignoreEvents([eventId]);
            const event = this.eventSet.remove(eventId);
            if (event) {
                this.ignoredEvents.append([event]);
            }
            // Events are ignored for all entities at once
            Object.entries(this.storeState.events).forEach(([_, eventSet]) => {
                eventSet.remove(eventId);
            });
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, (error as BadRequestException).message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async restoreIgnoredEvent(id: string) {
        let validationErrors: ValidationError[] = [];

        try {
            await this.eventsApiService.restoreIgnoredEvent(id);
            const event = this.ignoredEvents.remove(id);
            if (event) {
                this.eventSet.append([event]);
            }
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, (error as BadRequestException).message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    updateValidationErrors(validationErrors: ValidationError[], message: string) :ValidationError[] {
        const error = new ValidationError();
        error.constraints = { message };

        return [...validationErrors, ...[error]];
    }

    getEventCollection(date: Date, hotelId?: number): EventCollection | null {
        if (!hotelId) {
            return this.eventSet.getCollection(date);
        }

        const eventSet = this.storeState.events[hotelId];

        if (!eventSet || !eventSet.hasLoaded(date.getFullYear() as Year, date.getMonth() as Month)) {
            this.loadHotelEvent(date.getMonth() as Month, date.getFullYear() as Year, hotelId);
        }

        if (!eventSet) {
            return null;
        }

        return eventSet.getCollection(date);
    }

    getHolidayEvents(date: Date) {
        const { countries } = this.storeState.settings;
        const collection = this.getEventCollection(date);
        if (!collection) return [];

        return collection.holiday
            .filter(event => countries.includes(event.country!) || !event.country);
    }

    getEventsByCollection(collection: EventCollection | null = null) {
        const { types, countries } = this.storeState.settings;

        if (!collection) return [];

        const { chain, my, market } = collection;
        let { holiday } = collection;

        holiday = holiday
            .filter(event => countries.includes(event.country!) || !event.country);

        return ([] as EventsModel[])
            .concat(holiday, my, market, chain)
            .filter(event => event.group === EventGroup.HOLIDAY || types.includes(event.type!));
    }

    getEventsByDate(date: Date) {
        const collection = this.getEventCollection(date);
        return this.getEventsByCollection(collection);
    }

    getEventSetByHotel(hotelId: number, marketId?: number) {
        const { year, month } = this.documentFiltersService;
        let eventSet = this.storeState.events[hotelId];

        if (!eventSet) {
            this.storeState.events = {
                ...this.storeState.events,
                [hotelId]: new EventSet(),
            };

            eventSet = this.storeState.events[hotelId];
        }

        this.helperService.dynamicLoading(eventSet.loading, async () => {
            await this.loadHotelEvent(month, year, hotelId, marketId);
            return true;
        });

        return eventSet;
    }

    async getExcel(form: EventsExcelFormModel, hotelId?: number) {
        const excelData = await this.eventsApiService.getExcel(form, hotelId);
        if (!excelData) return false;

        await this.customNotificationService.handleExcel(excelData);
        return true;
    }

    hasDateHolidayEvents(date: Date): boolean {
        return !!this.getHolidayEvents(date).length;
    }

    hasDateOtherEvents(date: Date, hotelId?: number): boolean {
        const { types } = this.storeState.settings;
        const collection = this.getEventCollection(date, hotelId);

        if (!collection) return false;

        const { chain, my, market } = collection;

        return ([] as EventsModel[])
            .concat(chain, market, my)
            .some(event => types.includes(event.type!));
    }

    saveIsLoadEventByPOS(value: boolean) {
        if (this.carsService.storeState.settings.isLoadEventByPOS !== value) {
            this.carsService.storeState.settings.isLoadEventByPOS = value;
        }
    }
}
